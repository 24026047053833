AFRAME.registerComponent('cyber-funk-state-marker', {
  schema: { 
    markerId: {type: 'string'},
    activeStates: {type: 'array'},
    invisibleDelay_ms: {type: 'number', default: 0}
  },
  
  init: function() {
    const readyEvent = 'marker-states-ready';
    document.getElementById(this.data.markerId).addEventListener(readyEvent, (event) => {
      this.markerStates = event.detail.markerStates;
      this.markerStates.el.addEventListener('state-changed', (data) => this.onStateChanged(data));
    });
    
    this.initialScale = this.el.object3D.scale.clone();
    this.initialQuaternion = this.el.object3D.quaternion.clone();
    this.invisibleDelay_ms = this.data.invisibleDelay_ms;
    this.activeStates = this.data.activeStates;
    this.markerVisible = false;

    this.el.setAttribute('visible', false);
  },

  tick: function() {
    if (this.markerVisible) {
      let markerQuaternion = this.markerStates.el.object3D.quaternion.clone();
      let combinedQuaternion = markerQuaternion.multiply(this.initialQuaternion.clone());
      
      this.el.object3D.position.copy(this.markerStates.el.object3D.position);
      this.el.object3D.quaternion.copy(combinedQuaternion);
      return;
    }

    if (Date.now() - this.markerLostTimeStamp > this.invisibleDelay_ms) {
      this.el.setAttribute('visible', false);
    }
  },

  onStateChanged: function(data) {
    const visible = this.activeStates.includes(data.detail.current);

    if (!visible && this.markerVisible) {
      this.markerLostTimeStamp = Date.now();
    }
    
    this.markerVisible = visible;

    if (visible) {
      this.el.setAttribute('visible', visible);
    }
  }
});